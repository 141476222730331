import React, { useState } from 'react';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import useDataPost from '../../utils/useDataPost';
import '../css/general.css';

const UserRegistration = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const useHandleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setSuccess(false);

    const userData = {
      username,
      email,
      password
    };

    try {
      await useDataPost('https://booking-system-euro-3c75b18a7c36.herokuapp.com/auth/users/', userData);
      setSuccess(true);
      setUsername('');
      setEmail('');
      setPassword('');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Container className="container mt-5">
      <h1>Nieuw Account</h1>
      <Form onSubmit={useHandleSubmit}>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">Gebruiker succesvol geregistreerd!</Alert>}

        <Form.Group controlId="formUsername">
          <Form.Label>Gebruikersnaam</Form.Label>
          <Form.Control
            type="text"
            placeholder="Voer gebruikersnaam in"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formEmail" className="mt-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Voer email in"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formPassword" className="mt-3">
          <Form.Label>Wachtwoord</Form.Label>
          <Form.Control
            type="password"
            placeholder="Voer wachtwoord in"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit" className="mt-4">
          Registreren
        </Button>
      </Form>
    </Container>
  );
};

export default UserRegistration;
