import React, { useEffect, useState } from 'react';
import './css/login.css';
import leftImage from './assets/left.webp';
import logoImage from './assets/logo.webp';

const Login = ({ onPageChange }) => {
  const [validationError, setValidationError] = useState('');

  useEffect(() => {
    const handleSubmit = (event) => {
      event.preventDefault();
      const username = document.getElementById('username').value;
      const password = document.getElementById('password').value;

      if (username !== 'trattoria') {
        setValidationError('Access is restricted.');
        return;
      }

      fetch(window.baseDomain + 'auth/jwt/create/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.access) {
            const currentDate = new Date().toISOString().split('T')[0];
            localStorage.setItem('loginDate', currentDate);
            localStorage.setItem('restaurantName', username);
            localStorage.setItem('authKey', data.access);
            localStorage.setItem('refreshKey', data.refresh);
            onPageChange("reservaties"); // Trigger the page change to "reservaties"
          } else {
            setValidationError('Invalid credentials. Please try again.');
            console.error('Error: Invalid credentials');
          }
        })
        .catch((error) => {
          localStorage.setItem('login', 'failure');
          setValidationError('An error occurred. Please try again later.');
          console.error('Error:', error);
        });
    };

    const form = document.getElementById('signInForm');
    form.addEventListener('submit', handleSubmit);

    const appWrapper = document.getElementById('kt_app_wrapper');
    const appHeaderContainer = document.getElementById('kt_app_header_container');
    if (appWrapper) appWrapper.classList.add('hidden');
    if (appHeaderContainer) appHeaderContainer.classList.add('hidden');

    return () => {
      form.removeEventListener('submit', handleSubmit);
      if (appWrapper) appWrapper.classList.remove('hidden');
      if (appHeaderContainer) appHeaderContainer.classList.remove('hidden');
    };
  }, [onPageChange]);

  return (
    <div className="container-fluid mateza-container">
      <div className="row vh-100">
        <div className="col-md-6 d-none d-md-flex left-section flex-column justify-content-center align-items-center position-relative">
          <img src={leftImage} alt="Left Panel Image" className="left-panel-image img-fluid position-absolute" />
          <img src={logoImage} alt="Logo" className="logo img-fluid mb-4" />
          <h1 className="text-center">Mateza Admin</h1>
        </div>
        <div className="col-md-6 right-section d-flex justify-content-center align-items-center position-relative">
          <form className="sign-in-form w-75" id="signInForm">
            <h2 className="text-center mb-4">Aanmelden</h2>
            {validationError && (
              <div className="alert alert-danger" role="alert">
                {validationError}
              </div>
            )}
            <div className="form-group">
              <label htmlFor="username">Gebruikersnaam</label>
              <input type="text" className="form-control" id="username" placeholder="Voer je gebruikersnaam in" />
            </div>
            <div className="form-group">
              <label htmlFor="password">Wachtwoord</label>
              <input type="password" className="form-control" id="password" placeholder="Voer je wachtwoord in" />
            </div>
            <button type="submit" className="btn btn-primary btn-block">Aanmelden</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
