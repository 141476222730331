import React, { useState, useEffect } from 'react';
import useDataRetriever from '../../utils/useDataRetriever';
import '../css/general.css';
import '../css/day.css';

const UserList = () => {
  const { data, error, loading } = useDataRetriever('https://booking-system-euro-3c75b18a7c36.herokuapp.com/auth/users/');
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (error) {
      setHasError(true);
    }
  }, [error]);

  if (loading) {
    return <div>Laden...</div>;
  }

  if (hasError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className='container mt-5'>
      <h1>Gebruikerslijst</h1>
      <table className="ex-table js-ex-table" aria-label="Expandable table example">
        <thead className="ex-table__header">
          <tr className="ex-table__row">
            <th className="ex-table__cell" scope="col">ID</th>
            <th className="ex-table__cell" scope="col">Gebruikersnaam</th>
          </tr>
        </thead>
        <tbody className="ex-table__body js-ex-table__body">
          {data.length > 0 ? (
            data.map((user, index) => (
              <tr className="ex-table__row" key={index}>
                <td className="ex-table__cell" role="cell">
                  <span className="ex-table__label js-ex-table__label" aria-hidden="true">ID:</span> {user.id}
                </td>
                <td className="ex-table__cell" role="cell">
                  <span className="ex-table__label js-ex-table__label" aria-hidden="true">Username:</span> {user.username}
                </td>
              </tr>
            ))
          ) : (
            <tr className="ex-table__row">
              <td className="ex-table__cell" colSpan="2">
                No users found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default UserList;
