import React, { useState } from 'react';
import { Form, Button, Container, Alert } from 'react-bootstrap';
import useDataPost from '../../utils/useDataPost';
import '../css/general.css';

const RestaurantCreation = () => {
  const [displayName, setDisplayName] = useState('');
  const [tableCapacity, setTableCapacity] = useState('');
  const [allowedUser, setAllowedUser] = useState('');
  const [usesTables, setUsesTables] = useState(false);
  const [sendOwnerEmail, setSendOwnerEmail] = useState(false);
  const [ownerEmail, setOwnerEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const useHandleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setSuccess(false);

    const restaurantData = {
      display_name: displayName,
      table_capacity: parseInt(tableCapacity),
      allowed_user: allowedUser,
      uses_tables: usesTables,
      send_owner_email: sendOwnerEmail,
      owner_email: sendOwnerEmail ? ownerEmail : null,
    };

    try {
      await useDataPost('https://booking-system-euro-3c75b18a7c36.herokuapp.com/restaurants/', restaurantData);
      setSuccess(true);
      setDisplayName('');
      setTableCapacity('');
      setAllowedUser('');
      setUsesTables(false);
      setSendOwnerEmail(false);
      setOwnerEmail('');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Container className="container mt-5">
      <h1>Nieuw Restaurant</h1>
      <Form onSubmit={useHandleSubmit}>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">Restaurant succesvol aangemaakt!</Alert>}

        <Form.Group controlId="formDisplayName">
          <Form.Label>Naam</Form.Label>
          <Form.Control
            type="text"
            placeholder="Voer naam in"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formTableCapacity" className="mt-3">
          <Form.Label>Tafel Capaciteit</Form.Label>
          <Form.Control
            type="number"
            placeholder="Voer tafel capaciteit in"
            value={tableCapacity}
            onChange={(e) => setTableCapacity(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formAllowedUser" className="mt-3">
          <Form.Label>Toegestane Gebruiker</Form.Label>
          <Form.Control
            type="text"
            placeholder="Voer toegestane gebruiker in"
            value={allowedUser}
            onChange={(e) => setAllowedUser(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group controlId="formUsesTables" className="mt-3">
          <Form.Check
            type="checkbox"
            label="Gebruikt Tafels"
            checked={usesTables}
            onChange={(e) => setUsesTables(e.target.checked)}
          />
        </Form.Group>

        <Form.Group controlId="formSendOwnerEmail" className="mt-3">
          <Form.Check
            type="checkbox"
            label="Stuur Email naar Eigenaar"
            checked={sendOwnerEmail}
            onChange={(e) => setSendOwnerEmail(e.target.checked)}
          />
        </Form.Group>

        {sendOwnerEmail && (
          <Form.Group controlId="formOwnerEmail" className="mt-3">
            <Form.Label>Email Eigenaar</Form.Label>
            <Form.Control
              type="email"
              placeholder="Voer email van de eigenaar in"
              value={ownerEmail}
              onChange={(e) => setOwnerEmail(e.target.value)}
              required={sendOwnerEmail}
            />
          </Form.Group>
        )}

        <Button variant="primary" type="submit" className="mt-4">
          Aanmaken
        </Button>
      </Form>
    </Container>
  );
};

export default RestaurantCreation;
