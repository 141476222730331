import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));
const DEBUG = false;

if (DEBUG) {
  window.baseDomain = 'https://booking-system-test-02d7e73d8f66.herokuapp.com/';
  window.currentDomain = 'http://127.0.0.1:3000';

} else
{
  window.baseDomain = 'https://booking-system-euro-3c75b18a7c36.herokuapp.com/';
  window.currentDomain = 'https://admin.reservaties.net/';
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
